.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes Head-float-Website {
  0% {
    transform: translateY(-3vh);
  }
  50% {
    transform: translateY(3vh);
  }
  100% {
    transform: translateY(-3vh);
  }
}
@keyframes Head-float-Website-Shadow {
  0%,
  100% {
    transform: scale(1.3) skewX(-10deg);
  }
  50% {
    transform: scale(1) skewX(-30deg);
  }
}
@keyframes Head-float-mobile {
  0% {
    transform: translateY(-4vh);
  }
  50% {
    transform: translateY(4vh);
  }
  100% {
    transform: translateY(-4vh);
  }
}
@keyframes Head-float-mobile-Shadow {
  0%,
  100% {
    transform: scale(1.6) skewX(-10deg);
  }
  50% {
    transform: scale(1) skewX(-30deg);
  }
}
@keyframes story {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.banner {
  position: relative; /* NEW */
  height: 100vh;
  width: 100vw;
  /* background-color: red; */
  /* background-image: url("./assets/landingPageBackground.png"); */
  /* background-position: center; */
  /* background-size: cover; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
}

@media only screen and (min-width: 900px) {
  #myVideoLarge {
    position: absolute; /* CHANGED from fixed to absolute */
    top: 0; /* NEW */
    left: 0; /* NEW */
    width: 100%; /* CHANGED from min-width */
    height: 100%; /* CHANGED from min-height */
    object-fit: cover; /* NEW */
    z-index: 0;
  }
  #myVideoSmall {
    display: none;
  }
}
@media only screen and (max-width: 899px) {
  #myVideoSmall {
    position: absolute; /* CHANGED from fixed to absolute */
    top: 0; /* NEW */
    left: 0; /* NEW */
    width: 100%; /* CHANGED from min-width */
    height: 100%; /* CHANGED from min-height */
    object-fit: cover; /* NEW */
    z-index: 0;
  }
  #myVideoLarge {
    display: none;
  }
}

.banner-logo {
  position: absolute;
  height: 18vh;
  width: 18vh;
  background-color: white;
  top: 10%;
  left: 10%;
  display: flex;
  flex-direction: center;
  justify-content: center;
  border-radius: 99px;
  border: solid white 4px;
  z-index: 2;
}

.banner-logo img {
  height: 100%;
  width: 100%;
  border-radius: 99px;
}

.banner-logo :hover {
  transform: scale(1.25);
}

.banner-head {
  margin-bottom: 5vh;
  height: 40vw;
  width: 40vw;
  display: flex;
  justify-content: center;
  flex-direction: center;
  /* background-image: url("./assets/landingPageHead2.png"); */
  /* background-size: center; */
  /* background-repeat: no-repeat; */
  /* border: solid white 3px; */
  animation: Head-float-Website 6s infinite linear;
  z-index: 2;
}
.banner-head img {
  min-width: 115%;
  height: auto;
}
@keyframes eyeMovement {
  0% {
    transform: translateX(-30%);
  }
  50% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(-30%);
  }
}

.eye {
  animation: eyeMovement 4s infinite ease-in-out;
}
.stop-animation {
  animation: none !important;
}

.eye {
  position: absolute;
  width: 2%;
  height: 2%;
  /* border: solid white 1px; */
  border-radius: 99px;
  background-color: black;
}
.eye-left {
  top: 52.7%;
  left: 34.3%;
}
.eye-right {
  top: 52.4%;
  left: 60.8%;
}

.eye {
  position: absolute;
  width: 5%;
  height: 5%;
  border-radius: 99px;
  background-color: black;
}
.eye-left {
  top: 51.5%;
  left: 33.2%;
}
.eye-right {
  top: 51.1%;
  left: 59.8%;
}

.banner-bottom {
  height: 20vh;
  width: 100vw;
  /* background-color: red; */
  /* border: solid white 3px; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-bottom: 10vh;
}

.banner-bottom-left {
  height: 80%;
  width: 40%;
  /* border: solid white 3px; */
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-bottom-left a {
  width: 100%;
}
.banner-bottom-left img {
  /* border: solid white 2px; */
  width: 100%;
  animation: story 3s infinite ease-in-out;
}
.banner-bottom-left a:hover {
  border-radius: 99px;
  overflow: hidden;
  transform: scale(1.3);
  background-color: #cf1e1e;
  box-shadow: 0 0 50px 15px #cf1e1e;
  margin-bottom: 5%;
}
.banner-bottom-left a:hover img {
  animation: none;
  width: 90%;
}

.banner-bottom-right {
  height: 80%;
  width: 30%;
  /* border: solid white 3px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner-bottom-right img {
  /* border: solid white 2px; */
  margin-bottom: -20%;
  max-width: 100%;
  animation: story 3s infinite ease-in-out;
}
.banner-bottom-right img:hover {
  animation: none;
  transform: scale(1.25);
  margin-bottom: 10%;
}
.shadow {
  position: absolute;
  bottom: 3px;
  background-color: black;
  height: 11vh;
  width: 25vh;
  margin-top: -4vh;
  border-radius: 99px;
  box-shadow: 0 0 70px 50px #000000;
  -webkit-box-shadow: 0 0 70px 50px #000000;
  -moz-box-shadow: 0 0 70px 50px #000000;
  animation: Head-float-Website-Shadow 6s infinite ease-in-out;
}

.banner {
  position: relative;
}

.bird-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* adjust as necessary to layer the canvas correctly */
}

/* ////////////////////////////////////////// STORY */

#story {
  position: relative;
  height: 100vh;
  width: 100vw;
  /* background-image: url("./assets/StoryBackground.png"); */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  display: flex;
  justify-content: center;
  align-items: center;
}

#myVideoTwo {
  position: absolute; /* CHANGED from fixed to absolute */
  top: 0; /* NEW */
  left: 0; /* NEW */
  width: 100%; /* CHANGED from min-width */
  height: 100%; /* CHANGED from min-height */
  object-fit: cover; /* NEW */
  z-index: -1;
}
.form-container {
  transition: all 0.5s;
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 2vh;
}
.form-container form {
  transition: all 0.5s;
  height: 80%;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 99px;
  /* overflow: hidden; */
}
label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-container form label:nth-child(1) {
  height: 10%;
  width: 100%;
  /* background-color: black; */
  color: white;
}
.form-container form label:nth-child(1) input {
  height: 100%;
  width: 30%;
}
.form-container form label:nth-child(2) {
  /* background-color: black; */
  color: white;
  height: 70%;
  width: 100%;
}
.form-container form label:nth-child(2) textarea {
  height: 80%;
  width: 100%;
}
.form-container form button {
  height: 15%;
  width: 30%;
  border-radius: 99px;
  background-color: rgb(163, 35, 35);
  font-size: 1.5rem;
  animation: story 2s infinite ease-in-out;
}

.form-container form button:hover {
  transform: scale(1.2);
  animation: none;
}
.form-container.minimize {
  transform: scale(0.5);
  opacity: 0.8;
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.submission-success {
  text-align: center;
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid white 1px;
}
.submission-success h1 {
  height: 50%;
  width: 100%;
  color: white;
  font-size: 5.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.submission-success button {
  height: 50%;
  width: 100%;
  color: white;
  font-size: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* IPAD */
@media only screen and (max-width: 950px) {
  .banner-head {
    margin-bottom: 2vh;
    height: 64vw;
    width: 64vw;
    display: flex;
    justify-content: center;
    flex-direction: center;
    /* background-image: url("./assets/landingPageHead2.png"); */
    /* background-size: center; */
    /* background-repeat: no-repeat; */
    /* border: solid white 3px; */
    animation: Head-float-Website 6s infinite linear;
  }
  .banner-head img {
    min-width: 115%;
    height: auto;
  }
  .banner-bottom {
    height: 20vh;
    width: 100vw;
    /* background-color: red; */
    /* border: solid white 3px; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 6vh;
  }
  .banner-bottom-left {
    height: 80%;
    width: 50%;
    /* border: solid white 3px; */
    /* background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner-bottom-left a {
    width: 100%;
  }
  .banner-bottom-left img {
    /* border: solid white 2px; */
    width: 100%;
    animation: story 3s infinite ease-in-out;
  }
  .banner-bottom-right {
    height: 80%;
    width: 40%;
    /* border: solid white 3px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-container form button {
    height: 15%;
    width: 60%;
    border-radius: 99px;
    background-color: rgb(163, 35, 35);
    font-size: 1.5rem;
    animation: story 3s infinite ease-in-out;
  }
}
/* Mobile */
@media only screen and (max-width: 700px) {
  /* .banner {
    background-image: url("./assets/MobileLandingPage.png");
  } */
  /* .banner-logo img {
 content:url("/src/assets/tellastoryLine.png")

  } */
  .banner-logo {
    position: absolute;
    height: 18vh;
    width: 18vh;
    background-color: white;
    top: 1%;
    left: 3%;
    display: flex;
    flex-direction: center;
    justify-content: center;
    border-radius: 99px;
    border: solid white 4px;
  }

  .banner-head {
    margin-top: -13vh;
    margin-bottom: 2vh;
    height: 85vw;
    width: 85vw;
    display: flex;
    justify-content: center;
    flex-direction: center;
    /* background-image: url("./assets/landingPageHead2.png"); */
    /* background-size: center; */
    /* background-repeat: no-repeat; */
    /* border: solid white 3px; */
    animation: Head-float-mobile 7s infinite linear;
  }
  .banner-head img {
    min-width: 115%;
    height: auto;
  }
  .banner-bottom {
    height: 30vh;
    margin-bottom: -10vh;
    width: 100vw;
    /* background-color: red; */
    /* border: solid white 3px; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: -25vh;
  }
  .banner-bottom-left {
    height: 80%;
    width: 90%;
    /* border: solid white 3px; */
    /* background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .banner-bottom-left a {
    width: 100%;
  }
  .banner-bottom-left img {
    /* border: solid white 2px; */
    width: 100%;
    animation: story 2s infinite ease-in-out;
  }
  .banner-bottom-right {
    height: 80%;
    width: 90%;
    /* border: solid white 3px; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .banner-bottom-right a {
    max-width: 70%;
  }
  .banner-bottom-right a img {
    max-width: 70%;
  }
  .shadow {
    bottom: 6vh;
    background-color: black;
    height: 15vh;
    width: 22vh;
    border-radius: 99px;
    box-shadow: 0 0 50px 25px #000000;
    animation: Head-float-mobile-Shadow 7s infinite ease-in-out;
  }
  .form-container form button {
    height: 15%;
    width: 60%;
    border-radius: 99px;
    background-color: rgb(163, 35, 35);
    font-size: 1.5rem;
    animation: story 2s infinite ease-in-out;
  }

  .form-container form {
    transition: all 0.5s;
    height: 90%;
    width: 100%;
  }

  .form-container form button {
    height: 10%;
    width: 80%;
    border-radius: 99px;
    background-color: rgb(163, 35, 35);
    font-size: 1.5rem;
    animation: story 2s infinite ease-in-out;
  }

  .form-container form label:nth-child(1) input {
    height: 100%;
    width: 70%;
  }
  .form-container.minimize {
    transform: scale(0.55);
    opacity: 0.7;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .submission-success {
    text-align: center;
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid white 1px;
  }
}
